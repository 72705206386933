<template>
  <v-card>
    <div
      class="d-flex align-center py-4 px-2"
    >
      <div
        class="ml-4 d-flex flex-column"
      >
        <span class="mb-2 mt-2">Bonificação de crescimento</span>

        <div
          v-if="itemsTable.length > 0"
          class="d-flex items-center mb-2"
        >
          <v-icon
            size="20"
            class="mr-2"
          >
            {{ icons.mdiCalendarAccountOutline }}
          </v-icon>
          <span>REFERÊNCIA: {{ itemsTable[0].reference }}</span>
        </div>

        <div
          v-if="itemsTable.length > 0"
          class="d-flex items-center mb-2"
        >
          <v-icon
            size="20"
            class="mr-2"
          >
            {{ icons.mdiFlagVariantOutline }}
          </v-icon>
          <span>SAFRA: {{ itemsTable[0].safra }}</span>
        </div>
      </div>

      <v-spacer />

      <v-text-field
        v-model="filterData"
        class="mr-2 mt-6"
        label="Buscar por nome"
        outlined
        dense
        @keyup.enter="findFilterResult()"
      />

      <v-btn
        :color="itemsTable.length > 0 ? 'success' : 'gray'"
        class="me-3"
        outlined
        @click="openModalFilter()"
      >
        <v-icon
          size="20"
          class="me-1"
        >
          {{ icons.mdiFilterVariant }}
        </v-icon>
        <span>
          FILTRO
        </span>
      </v-btn>

      <v-btn
        v-show="itemsTable.length > 0"
        class="mr-4"
        color="error"
        outlined
        @click="clearGrid"
      >
        <v-icon size="30">
          {{ icons.mdiFilterVariantRemove }}
        </v-icon>

        <span class="ml-2">Limpar</span>
      </v-btn>

      <v-btn
        v-show="itemsTable.length > 0"
        class="mr-2"
        color="warning"
        outlined
        v-bind="attrs"
        v-on="on"
        @click="exportCsv"
      >
        <span class="ml-2">Exportar</span>
        <v-img
          class="ml-2"
          :src="require('@/assets/download-icon.svg')"
        />
      </v-btn>
    </div>

    <v-data-table
      :items="listOfFilteredItems"
      :headers="headers"
      :loading="loading"
      loading-text="Carregando dados..."
      class="text-no-wrap"
      disable-sort
    >
      <template #[`item.percentage`]="{ item }">
        {{ Number(item.percentage).toFixed(2) }}%
      </template>

      <template #[`item.growth_bonus`]="{ item }">
        {{ item.growth_bonus
          ? Number(item.growth_bonus).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
          : 'R$ 0,00' }}
      </template>
    </v-data-table>

    <v-dialog
      v-model="openModal"
      max-width="500px"
      persistent
    >
      <ModalFiltergrowthBonus
        @close="openModal = false"
        @filteredData="$event => setTableData($event)"
        @filteredBody="$event => setBodyFilter($event)"
      ></ModalFiltergrowthBonus>
    </v-dialog>
  </v-card>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import formatters from '@/plugins/formattersMixin1'
import {
  mdiCalendarAccountOutline,
  mdiFilterVariant,
  mdiFilterVariantRemove,
  mdiFlagVariantOutline,
  mdiMagnify, mdiPencil,
  mdiPlaylistPlus,
  mdiPlus,
  mdiProgressClose,
} from '@mdi/js'
import { saveAs } from 'file-saver'
import localStorageSlim from 'localstorage-slim'
import ModalFiltergrowthBonus from './ModalFiltergrowthBonus.vue'

const ExcelJS = require('exceljs')

export default {
  components: {
    ModalFiltergrowthBonus,
  },

  mixins: [formatters],

  data() {
    return {
      listOfFilteredItems: [],
      filterData: '',
      vendorFilter: '',
      isOnVacation: false,
      pageOptions: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
      },
      isFiltered: false,
      mode: 'edit',
      loadingSpinner: '',
      loading: false,
      openModal: false,
      openModalFilterTarget: false,
      openModalGlobal: false,
      openNewRegister: false,
      icons: {
        mdiFlagVariantOutline,
        mdiCalendarAccountOutline,
        mdiFilterVariant,
        mdiPlaylistPlus,
        mdiMagnify,
        mdiPlus,
        mdiProgressClose,
        mdiPencil,
        mdiFilterVariantRemove,
      },
      headers: [
        { text: 'FILIAL', value: 'region', align: '' },
        { text: 'LOJA', value: 'fantasy_name', align: '' },
        { text: 'FUNCIONÁRIO', value: 'name', align: 'center' },
        { text: 'CPF', value: 'cpf', align: 'center' },
        { text: 'NÍVEL', value: 'level', align: '' },
        { text: 'META ATUAL', value: 'goal', align: 'center' },
        { text: 'REALIZADO', value: 'sales', align: 'center' },
        { text: 'EXCEDENTE', value: 'exceed_standard_goals', align: 'center' },
        { text: 'ADIMPLÊNCIA', value: 'percentage', align: 'center' },
        { text: 'VALOR DO BÔNUS', value: 'growth_bonus', align: 'center' },
      ],
      items: [],
      vendorList: [],
      dataDetails: {},
      endpointDelete: 'api/v1/integration/cnh_parameter/destroy',
      filterApplied: false,
      itemsTable: [],
      bodyFilter: {},
    }
  },
  computed: {
    filterButtonColor() {
      return this.isFiltered ? 'success' : 'white'
    },
    computedWidth() {
      return this.mode === 'edit' ? 650 : 1000
    },
  },

  watch: {
    filterData() {
      this.findFilterResult()
    },
  },

  created() {
    this.vendorList = localStorageSlim.get('listEmployees', { decrypt: true })
    this.getItemsTable()
  },

  methods: {
    findFilterResult() {
      const { itemsTable, filterData } = this
      const filterDataLower = filterData.toLowerCase()

      this.listOfFilteredItems = itemsTable.filter(({ name }) => {
        const employee = name.toLowerCase()

        return employee.includes(filterDataLower)
      })
    },

    async exportCsv(item) {
      const file = await this.getCsv(item)
      if (file) {
        saveAs(file)
      }
    },

    async getCsv() {
      this.loaderExport = true
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('sheet')

      worksheet.columns = [
        { header: 'FILIAL', key: 'region' },
        { header: 'LOJA', key: 'fantasy_name' },
        { header: 'FUNCIONÁRIO', key: 'name' },
        { header: 'CPF', key: 'cpf' },
        { header: 'NÍVEL', key: 'level' },
        { header: 'META ATUAL', key: 'goal' },
        { header: 'REALIZADO', key: 'sales' },
        { header: 'EXCEDENTE', key: 'exceed_standard_goals' },
        { header: 'ADIMPLÊNCIA', key: 'percentage' },
        { header: 'VALOR DO ', key: 'growth_bonus' },
      ]

      this.listOfFilteredItems.map(itemTable => {
        worksheet.addRow([
          itemTable.region,
          itemTable.fantasy_name,
          itemTable.name,
          itemTable.cpf,
          itemTable.level,
          itemTable.goal,
          itemTable.sales,
          itemTable.exceed_standard_goals,
          Number(itemTable.percentage).toFixed(2),
          itemTable.growth_bonus,
        ])
      })

      let blob = null
      await workbook.xlsx.writeBuffer().then(data => {
        blob = new File([data], 'dados_bonus_crescimento', {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
      })

      this.loaderExport = false

      return blob
    },
    clearGrid() {
      this.listOfFilteredItems = []
      this.itemsTable = []
    },

    setTableData(data) {
      this.listOfFilteredItems = data
      this.itemsTable = data
    },

    setBodyFilter(body) {
      this.bodyFilter = body
    },

    openModalFilter() {
      this.openModal = true
    },
  },
}
</script>

<style>
.color-cpf-vendor {
  color: #85819c;
}
.chip-vacations{
  background: #56CA001A;
  color: #56CA00;
  font-weight: 600;
  padding: 2px 0px 2px 0px;
}
</style>
