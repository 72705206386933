<template>
  <div>
    <v-card>
      <v-card-title class="justify-center mb-4">
        <v-spacer />

        <span>
          Filtro Bonificação
        </span>

        <v-spacer />

        <v-icon
          @click="closeModal()"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </v-card-title>

      <v-card-text>
        <separator label="Filtrar dados" />

        <v-autocomplete
          v-model="branch"
          :items="listRegionsItems"
          label="Filial"
          outlined
          dense
          class="mt-8"
          md="2"
        />

        <v-autocomplete
          v-model="store"
          :items="listStore"
          item-text="fantasy_name"
          item-value="fantasy_name"
          label="Loja"
          outlined
          dense
        />
        <v-menu
          v-model="monthReference"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="monthRefeReferenceValue"
              label="Mês referência"
              hide-details
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="monthRefeReferenceValue"
            type="month"
            class="datePicker p-0"
            width="500px"
            locale="pt-BR"
          />
        </v-menu>

        <div class="actions-filter">
          <v-btn
            color="error"
            outlined
            @click="clearFilter()"
          >
            <v-icon
              size="20"
              class="mr-2"
            >
              {{ icons.mdiFilterVariantRemove }}
            </v-icon>
            <span>Limpar</span>
          </v-btn>

          <v-btn
            color="info"
            class="ml-5"
            @click="getFilterData()"
          >
            <span v-if="!isLoading">Filtrar</span>
            <v-progress-circular
              v-else
              color="white"
              indeterminate
            >
            </v-progress-circular>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Separator from '@/components/Separator.vue'
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import utilsMixin from '@/plugins/utilsMixin'
import { mdiClose, mdiFilterVariantRemove } from '@mdi/js'

export default {

  components: { Separator },
  mixins: [formatters, messages, utilsMixin],

  data() {
    return {
      branch: '',
      icons: {
        mdiClose,
        mdiFilterVariantRemove,
      },
      listRegionsItems: ['IMPERATRIZ', 'AÇAILÂNDIA', 'GRAJAÚ'],
      store: '',
      listStore: [],
      monthReference: false,
      monthRefeReferenceValue: '',
      isLoading: false,
    }
  },
  computed: {
    computedMonthReference() {
      return this.dateFormat(this.monthRefeReferenceValue)
    },
  },

  created() {
    this.getListStore()
  },
  methods: {
    clearFilter() {
      this.branch = ''
      this.store = ''
      this.monthRefeReferenceValue = ''
      this.vacation = false
    },

    async getListStore() {
      try {
        const response = await axiosIns.get('/api/v1/records/company/index')

        this.listStore = response.data.data
      } catch (error) {
        this.showMessage({
          title: 'Erro ao buscar a lista de lojas!',
          text: error,
          icon: 'error',
        })
      }
    },

    async getFilterData() {
      this.isLoading = true
      try {
        const body = {
          region: this.branch,
          reference: this.monthRefeReferenceValue,
          company: this.store,
        }
        const response = await axiosIns.post('/api/v1/integration/growth_bonus/filter', body)

        this.sendFilteredData(response.data, body)
        this.closeModal()
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },

    sendFilteredData(data, body) {
      this.$emit('filteredData', data)
      this.$emit('filteredBody', body)
    },
  },
}
</script>

<style scoped>
  .datePicker {
  width: 100%;
}

.vacation{
  display: flex;
  align-items: center;
}

.vacation p{
  margin-top: 12px;
}

.actions-filter{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
</style>
